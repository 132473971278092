//================================================================
//  Component: Disclaimer Modal component
//================================================================

//  Purpose: Users must accept our disclaimer & have asked less than 500 questions
//           If so, they can proceed to the App

//  Example:
//    <DisclaimerModal></DisclaimerModal>    

//================================================================


//Libraries
import React, { useState, useEffect, useContext } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components

//Functions
import writeDocument from '../../Library/WriteDocument';
import queryDocument from '../../Library/QueryDocument';

//Images
import importantIcon from '../../Components/Images/Icon_Important_Blue.svg';
import lightBlub from '../../Components/Images/Icon_LightBlub_Blue.svg';

//CSS
import './DisclaimerModal.css';


export default function DisclaimerModal() {

  //------------------------------------------------------
  //  Global Variables
  //------------------------------------------------------

    // Size of the quota before CSM's are alerted
    const maxQuota = 500;

  //------------------------------------------------------
  //  useStates & useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

    // If the modal is open/closed
    const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(true);

    // If the modal is open/closed
    const [quotaModalOpen, setQuotaModalOpen] = useState(false);

  
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onLoad
    //  1. Check if quote should be enabled via the ENV file
    //  2. If so, check if the user has exceed their quota of 500 requests!
    useEffect(() => {

      if (process.env.REACT_APP_QUOTA_ENABLED !== 'YES') return;
      if (getUser.quota === undefined) return;
      if (getUser.quota < maxQuota) return;

      // Toggle the correct modals
      setDisclaimerModalOpen(false);
      setQuotaModalOpen(true);

      // Check if we have sent an alert
      queryDocument('alerts', [
        ['emailaddress', '==', getUser.emailaddress]
      ]).then((alertsSent) => {

        if (alertsSent.length > 0) return;

        // No alert sent --> send another alert!
        const document = {
          'id': `${getUser.emailaddress}-${Date.now()}`,
          'emailaddress': getUser.emailaddress,
          'date': new Date(),
          'quota': getUser.quota,
          'status': 'pending',
        }
        return writeDocument('alerts', document.id, document, true).then(() => {

          setQuotaModalOpen(true);

        });

      });

    }, [getUser]);

  
  //------------------------------------------------------
  //  Disclaimer Modal
  //------------------------------------------------------

  if (disclaimerModalOpen === true) {

    return (
      <div className='Modal-Background'>
        
        <dialog className='DisclaimerModal-Container'>
  
          {/* Page One */}
          <div className='DisclaimerModal-Page'>
  
            <div className='DisclaimerModal-Content'>
              <h4 className='p-[5px]'>
                Welcome to Lendlease GenAI 
              </h4>
  
              <div className='DisclaimerModal-Item'>
                <img src={lightBlub} alt='question-icon' style={{width: '25px'}}></img>
                Ask up to 500 questions
              </div>
  
              <div className='DisclaimerModal-Rules'>
  
                <div className=' flex flex-row'>
                  <img src={importantIcon} alt='important-icon' style={{width: '25px'}}></img>
                  Couple of guidelines to follow:
                </div>
  
                <div>
                  <ul className='list-disc'>
                    <li>
                      Only disclose sensitive or confidential Lendlease data to Lendlease managed AI tools, like this one. If you are unsure what these tools are please speak to your local CSM.
                    </li>
                    <li>
                      While we have put safeguards in place, the system may occasionally generate incorrect answers, so please fact check.
                    </li>
                    <li>
                      If you have used AI to generate an outcome disclose it
                    </li>
                  </ul>
                  <br></br>
                  If you would like more information on AI at Lendlease please reach out to your Customer Success Managers and they will be able to assist you.
                </div>
  
              </div>
  
            </div>
  
            <div className='DisclaimerModal-Button-Single'>
              <button className='DisclaimerModal-Primary-Button' onClick={() => setDisclaimerModalOpen(false)}>Accept the guidelines</button>
            </div>
  
          </div>
  
        </dialog>
  
      </div>
    )

  };


  //------------------------------------------------------
  //  Quota Modal
  //------------------------------------------------------

  if (quotaModalOpen === true) {

    return (
      <div className='Modal-Background'>
        
        <dialog className='DisclaimerModal-Container'>
  
          {/* Page One */}
          <div className='DisclaimerModal-Page'>
  
            <div className='DisclaimerModal-Content'>
              <h4>
                Quota limit reached
              </h4>
  
              <div className='DisclaimerModal-Item'>
                <img src={lightBlub} alt='question-icon' style={{width: '25px'}}></img>
                Well done! You have asked over 500 questions
              </div>
  
              <div className='DisclaimerModal-Rules'>
  
                <div>
                  Our friendly Customer Success Managers will be reaching out shortly to discuss Lendleases GenAI options!
                </div>
  
              </div>
  
            </div>
  
          </div>
  
        </dialog>
  
      </div>
    )

  };


  //------------------------------------------------------
}
