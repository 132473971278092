//================================================================
//  Application: Lendlease AI
//================================================================

//  Product Owner: Gary Searle
//  Created 22/11/23

//================================================================

//Libraries
import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

//Contexts
import { SetAppSize } from './Library/GlobalContexts.js';

//Pages
import NotFound from './Pages/NotFound/NotFound.js';
import Chat from './Pages/Chat/Chat.js';

//Components
import Navbar from './Components/Navbar/Navbar';
import DisclaimerModal from './Components/DisclaimerModal/DisclaimerModal.js';

//Styles
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setAppSize = useContext(SetAppSize);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------
  
    function handleResize(e){

      setAppSize({
        'width': e.currentTarget.innerWidth,
        'height': e.currentTarget.innerHeight
      });

    };

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // onLoad
    // 1. Setup an event listener to get width and height of app
    useEffect(() => {

      setAppSize({
        'width': window.innerWidth,
        'height': window.innerHeight
      });

      window.addEventListener('load', handleResize)
      window.addEventListener('resize', handleResize)
    
      return () => {
        window.removeEventListener('load', handleResize)
        window.removeEventListener('resize', handleResize)
      }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <div className='App-Container'>

      {/* Nav */}
      <div className='App-Container-Nav'>
        <Navbar></Navbar>
      </div>

      {/* Body > Contains all pages in the app  */}
      <div className='App-Container-Body'>
       
        {/* Disclaimer & Quota Limit notice */}
        {
          process.env.REACT_APP_QUOTA_ENABLED === 'YES' && (
            <DisclaimerModal></DisclaimerModal>
          )
        }

        <Routes>
          <Route path='/' element={<Chat />} />

          <Route path='*' element={<NotFound />}></Route>
        </Routes>

      </div>

    </div>
  )

  //------------------------------------------------------
}